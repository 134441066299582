<template>
  <div :class="$style.root">
    <VForm ref="form" v-model="valid" lazy-validation @submit.prevent="onDraft">
      <DisclosureAgreementSection v-bind="{...values['BASIS_FOR_NDA']}" v-slot="{ values }">
        <VRow v-for="(item, index) in values" :class="$style.row" :key="index">
          <VCol v-for="code in ['NDA_TEMPLATE_ID', 'APPROVAL_STATUS', 'NUMBER']" cols="12" sm="6" :key="code">
            <TypedField
              readonly
              :rules="[!!item[code]['value'] || 'Обязательное поле']"
              v-bind="{...item[code]}"
            />
          </VCol>
        </VRow>
      </DisclosureAgreementSection>
      <DisclosureAgreementSection v-bind="{...fields['COUNTERPARTY']}" v-slot="{ values }">
        <VRow v-for="(item, index) in values" :class="$style.row" :key="index">
          <VCol v-for="code in ['SECOND_NAME', 'FIRST_NAME', 'MIDDLE_NAME']" cols="12" sm="6" md="4" :key="code">
            <TypedField
              :rules="[!!item[code]['value'] || 'Обязательное поле']"
              v-bind="{...item[code]}"
              @input="(value) => item[code]['value'] = value"
            />
          </VCol>
          <VCol v-for="code in ['PHONE']" cols="12" sm="6" md="4" :key="code">
            <TypedField
              :rules="[
                !!item[code]['value'] || 'Обязательное поле',
                /^[+]?\d*$/.test(item[code]['value']) || 'Укажите корректный телефон',
              ]"
              v-bind="{...item[code]}"
              @input="(value) => item[code]['value'] = value"
            />
          </VCol>
          <VCol v-for="code in ['EMAIL']" cols="12" sm="6" md="4" :key="code">
            <TypedField
              :rules="[
                !!item[code]['value'] || 'Обязательное поле',
                /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(item[code]['value']) || 'Укажите корректный email',
              ]"
              v-bind="{...item[code]}"
              @input="(value) => item[code]['value'] = value"
            />
          </VCol>
        </VRow>
      </DisclosureAgreementSection>
      <DisclosureAgreementSection v-bind="{...fields['RESPONSIBLE_INITIATOR']}" v-slot="{ values }">
        <VRow v-for="(item, index) in values" :class="$style.row" :key="index">
          <VCol v-for="code in ['INITIATOR_EMAIL']" cols="12" sm="6" md="4" :key="code">
            <TypedField
              :rules="[
                !!item[code]['value'] || 'Обязательное поле',
                /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(item[code]['value']) || 'Укажите корректный email',
                /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9_.+-]+\.)?evraz\.com$/.test(item[code]['value']) || 'Email может быть в домене evraz.com',
             ]"
              v-bind="{...item[code]}"
              @input="(value) => item[code]['value'] = value"
            />
          </VCol>
        </VRow>
      </DisclosureAgreementSection>
      <DisclosureAgreementSection v-bind="{...fields['RESPONSIBLE_COUNTERPARTY']}" v-slot="{ values }">
        <VRow v-for="(item, index) in values" :class="$style.row" :key="index">
          <VCol v-for="code in ['SECOND_NAME', 'FIRST_NAME', 'MIDDLE_NAME']" cols="12" sm="6" md="4" :key="code">
            <TypedField
              :rules="[!!item[code]['value'] || 'Обязательное поле']"
              v-bind="{...item[code]}"
              @input="(value) => item[code]['value'] = value"
            />
          </VCol>
          <VCol v-for="code in ['EMAIL']" cols="12" sm="6" md="4" :key="code">
            <TypedField
              :rules="[
                !!item[code]['value'] || 'Обязательное поле',
                /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(item[code]['value']) || 'Укажите корректный email',
              ]"
              v-bind="{...item[code]}"
              @input="(value) => item[code]['value'] = value"
            />
          </VCol>
          <VCol v-if="values.length > 1" cols="12" class="pt-0">
            <VBtn @click="onSplice(fields['RESPONSIBLE_COUNTERPARTY']['values'], index)" depressed small>Удалить</VBtn>
          </VCol>
        </VRow>
        <div class="pt-5">
          <VBtn color="primary" depressed @click="onPush(fields['RESPONSIBLE_COUNTERPARTY']['values'])">
            Добавить
          </VBtn>
        </div>
      </DisclosureAgreementSection>
      <DisclosureAgreementSection v-bind="{...fields['SIGNER_TYPE']}" v-slot="{ values }">
        <VRow v-for="(item, index) in values" :class="$style.row" :key="index">
          <VCol v-for="code in ['SIGNER_LEGAL_BASIS']" cols="12" sm="6" md="4" :key="code">
            <TypedField
              type="RADIO"
              :rules="[!!item[code]['value'] || 'Обязательное поле']"
              v-bind="{...item[code]}"
              @input="(value) => item[code]['value'] = value"
            />
          </VCol>
          <VCol v-if="item['SIGNER_LEGAL_BASIS']['value'] === 'POA'" cols="12">
            <DisclosureAgreementSection v-bind="{...fields['POA_DATA']}" v-slot="{ values }">
              <VRow v-for="(item, index) in values" :class="$style.row" :key="index">
                <VCol v-for="code in ['NUMBER']" cols="12" sm="6" md="4" :key="code">
                  <TypedField
                    :rules="[!!item[code]['value'] || 'Обязательное поле']"
                    v-bind="{...item[code]}"
                    @input="(value) => item[code]['value'] = value"
                  />
                </VCol>
                <VCol v-for="code in ['DATE']" cols="12" sm="6" md="4" :key="code">
                  <TypedField
                    type="DATE"
                    :max="nowISO"
                    :rules="[!!item[code]['value'] || 'Обязательное поле']"
                    v-bind="{...item[code]}"
                    @input="(value) => item[code]['value'] = value"
                  />
                </VCol>
              </VRow>
            </DisclosureAgreementSection>
            <DisclosureAgreementSection v-bind="{...fields['POA_FILES']}" v-slot="{ values }">
              <VRow v-for="(item, index) in values" :class="$style.row" :key="index">
                <VCol v-for="code in ['FILES']" cols="12" sm="6" md="4" :key="code">
                  <FileInput
                    type="POWER_OF_ATTORNEY"
                    :rules="({ value, size = 0 }) => [
                      !!value || 'Обязательное поле',
                      (+size < 52428800) || 'Размер файла не должен превышать 50Mb',
                    ]"
                    v-bind="{...item[code]}"
                    @input="(value) => item[code]['value'] = value"
                  />
                </VCol>
                <VCol v-if="values.length > 1" cols="12" class="pt-0">
                  <VBtn @click="onSplice(fields['POA_FILES']['values'], index)" depressed small>Удалить</VBtn>
                </VCol>
              </VRow>
              <div class="pt-5">
                <VBtn color="primary" depressed @click="onPush(fields['POA_FILES']['values'])">
                  Добавить
                </VBtn>
              </div>
            </DisclosureAgreementSection>
          </VCol>
        </VRow>
      </DisclosureAgreementSection>
      <DisclosureAgreementSection v-bind="{...fields['NDA_PURPOSE_SECTION']}" v-slot="{ values }">
        <VRow v-for="(item, index) in values" :class="$style.row" :key="index">
          <VCol v-for="code in ['NDA_PURPOSE']" cols="12" sm="6" md="4" :key="code">
            <TypedField
                type="RADIO"
                :rules="[!!item[code]['value'] || 'Обязательное поле']"
                v-bind="{...item[code]}"
                @input="(value) => item[code]['value'] = value"
            />
          </VCol>
          <VCol v-if="item['NDA_PURPOSE']['value'] === 'OPERATING_ACTIVITIES'" cols="12">
            <DisclosureAgreementSection v-bind="{...fields['BASIS_FOR_CONCLUDING']}" v-slot="{ values }">
              <VRow v-for="(item, index) in values" :class="$style.row" :key="index">
                <VCol v-for="code in ['BASE']" cols="12" :key="code">
                  <TypedField
                      type="TEXTAREA"
                      placeholder="передачи информации при реализации проекта 9999 «Оформление соглашений о конфиденциальности»"
                      v-bind="{...item[code]}"
                      @input="(value) => item[code]['value'] = value"
                  />
                </VCol>
              </VRow>
            </DisclosureAgreementSection>
          </VCol>
        </VRow>
      </DisclosureAgreementSection>
      <DisclosureAgreementSection v-bind="{...fields['NDA_FORM_SECTION']}" v-slot="{ values }">
        <VRow v-for="(item, index) in values" :class="$style.row" :key="index">
          <VCol v-for="code in ['NDA_FORM']" cols="12" sm="6" md="3" lg="2" :key="code">
            <TypedField
              type="RADIO"
              :rules="[!!item[code]['value'] || 'Обязательное поле']"
              v-bind="{...item[code]}"
              @input="(value) => item[code]['value'] = value"
            />
          </VCol>
          <VCol cols="12" sm="6" md="6">
            <div class="mb-1 mt-1">Формы для ознакомления</div>
            <a class="mr-3 d-inline-block" download target="_blank" :href="url('/nda/assets/operational-activity.docx')">Операционная деятельность</a>
            <a class="d-inline-block" download target="_blank" :href="url('/nda/assets/investment-activity.docx')">Инвест. деятельность</a>
          </VCol>
          <VCol v-if="item['NDA_FORM']['value'] === 'STANDARD'" cols="12">
            <DisclosureAgreementSection v-bind="{...fields['ORGANIZATIONS_EVRAZ']}" v-slot="{ values }">
              <VRow v-for="(item, index) in values" :class="$style.row" :key="index">
                <VCol v-for="code in ['ORGANIZATIONS']" cols="12" :key="code">
                  <VRow no-gutters :key="index">
                    <VCol v-for="{ value, text } in item[code]['options']" cols="12" md="6" lg="4" xl="3" :key="value">
                      <VCheckbox
                        v-model="item[code]['value']"
                        :label="text"
                        :value="value"
                        :rules="[item[code]['value'].length > 0 || 'Обязательное поле.']"
                        dense
                        hide-details
                      />
                    </VCol>
                    <VCol v-if="!item[code]['value'].length && !valid" cols="12" class="red--text ">
                      <div class="v-messages">
                        {{!!item[code]['value'].length || 'Обязательное поле'}}
                      </div>
                    </VCol>
                  </VRow>
                </VCol>
              </VRow>
            </DisclosureAgreementSection>
          </VCol>
          <VCol v-if="item['NDA_FORM']['value'] === 'PARTNER'" cols="12">
            <DisclosureAgreementSection v-bind="{...fields['NDA_FORM_PARTNER_DATA']}" v-slot="{ values }">
              <VRow v-for="(item, index) in values" :class="$style.row" :key="index">
                <VCol v-for="code in ['FILE']" cols="12" sm="6" md="4" :key="code">
                  <FileInput
                    type="PARTNER_NDA"
                    :rules="({ value, size = 0 }) => [
                      !!value || 'Обязательное поле',
                      /doc[x]?/.test(value) || 'Только редактируемый формат (doc, docx)',
                      (+size < 52428800) || 'Размер файла не должен превышать 50Mb',
                    ]"
                    v-bind="{...item[code]}"
                    @input="(value) => item[code]['value'] = value"
                  />
                  <div class="pt-1">
                    Допустимый формат doc, docx
                  </div>
                </VCol>
                <VCol v-for="code in ['DATE']" cols="12" sm="6" md="4" :key="code">
                  <TypedField
                    type="DATE"
                    :rules="[!!item[code]['value'] || 'Обязательное поле']"
                    :min="nowISO"
                    v-bind="{...item[code]}"
                    @input="(value) => item[code]['value'] = value"
                  />
                  <div class="pt-1">
                    Срок действия должен соответствовать дате в тексте соглашения
                  </div>
                </VCol>
              </VRow>
            </DisclosureAgreementSection>
          </VCol>
        </VRow>
      </DisclosureAgreementSection>
      <DisclosureAgreementSection v-bind="{...fields['DOCUMENTS_ACCOMPANY']}" v-slot="{ values }">
        <VRow v-for="(item, index) in values" :class="$style.row" :key="index">
          <VCol v-for="code in ['FILES']" cols="12" sm="6" md="4" :key="code">
            <FileInput
              type="RELATED_DOC"
              :rules="({ size = 0 }) => [
                (+size < 52428800) || 'Размер файла не должен превышать 50Mb',
              ]"
              v-bind="{...item[code]}"
              @input="(value) => item[code]['value'] = value"
            />
          </VCol>
          <VCol v-if="values.length > 1" cols="12" class="pt-0">
            <VBtn @click="onSplice(fields['DOCUMENTS_ACCOMPANY']['values'], index)" depressed small>Удалить</VBtn>
          </VCol>
        </VRow>
        <div class="pt-5">
          <VBtn color="primary" depressed @click="onPush(fields['DOCUMENTS_ACCOMPANY']['values'])">
            Добавить
          </VBtn>
        </div>
      </DisclosureAgreementSection>
      <VFooter app>
        <div class="grow py-3">
          <VBtn color="primary" depressed class="mr-3" :disabled="!valid" @click="onAgreement">
            <VIcon v-if="$vuetify.breakpoint.name === 'xs'">mdi-send</VIcon>
            <template v-else>Отправить на согласование</template>
          </VBtn>
          <VBtn color="primary" depressed class="mr-3" :disabled="!viable" @click="onDraft">
            <VIcon v-if="$vuetify.breakpoint.name === 'xs'">mdi-cloud-upload-outline</VIcon>
            <template v-else>Сохранить черновик</template>
          </VBtn>
          <VBtn color="secondary" depressed class="mr-3" @click="onBackPage">
            Назад
          </VBtn>
        </div>
      </VFooter>
    </VForm>
  </div>
</template>

<script>
import {map, get, pick, compact, head, mapValues, reject} from 'lodash-es';
import FileInput from '@/components/nda/FileInput/FileInput';
import TypedField from '@/components/nda/TypedField/TypedField';
import DisclosureAgreementSection from '@/components/nda/DisclosureAgreementSection/DisclosureAgreementSection';
export default {
  name: 'DisclosureAgreement',
  components: {
    FileInput,
    TypedField,
    DisclosureAgreementSection,
  },
  props: {
    id: { type: String },
    values: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      valid: true,
      fields: JSON.parse(JSON.stringify(this.values)),
    }
  },
  computed: {
    diff() {
      return JSON.stringify(this.values) !== JSON.stringify(this.fields);
    },
    // Минимальное условия для сохранения "как черновик"
    viable() {
      const keys = ['FIRST_NAME', 'SECOND_NAME', 'MIDDLE_NAME'];
      return compact(map(pick(get(this.fields, ['COUNTERPARTY', 'values', 0]), keys), 'value')).length === keys.length;
    },
    nowISO() {
      return new Date().toISOString();
    },
    ndaPurposeSectionValue() {
      return this.fields['NDA_PURPOSE_SECTION']['values'];
    }
  },
  methods: {
    setStatus(status) {
      this.fields['BASIS_FOR_NDA']['values'][0]['APPROVAL_STATUS']['value'] = status;
    },
    url(href) {
      return process.env.VUE_APP_PUBLIC_PATH + href;
    },
    onPush: function(values) {
      const value = mapValues(JSON.parse(JSON.stringify(head(values))), (item) => {
        item.id = '';
        item.value = '';
        return item;
      });
      values.push(value);
    },
    onSplice: function(values, index) {
      values.splice(index, 1);
    },
    onSubmit: function() {
      this.$emit('submit', { values: this.fields });
    },

    onReset: function() {
      this.fields = JSON.parse(JSON.stringify(this.values));
    },
    onAgreement: function() {
      if (this.$refs.form.validate()) {
        this.setStatus('SENT_FOR_APPROVAL');
        this.onSubmit();
      } else {
        this.$notify({
          type: 'error',
          title: 'Ошибка',
          text: 'Не заполнены обязательные поля формы',
        });
      }
    },
    onDraft: function() {
      if (this.viable) {
        this.setStatus('INITIATIVE_SAVED');
        this.onSubmit();
      }
    },
    onBackPage: function() {
      if (window.history.length && !window.document.referrer) window.history.back();
      else window.location.href = '/';
    },
  },
  watch: {
    values: {
      deep: true,
      immediate: true,
      handler() {
        this.onReset();
      }
    },
    ndaPurposeSectionValue: {
      deep: true,
      immediate: true,
      handler() {
        this.fields['NDA_FORM_SECTION']['values'][0]['NDA_FORM']['options'] = JSON.parse(JSON.stringify(this.values['NDA_FORM_SECTION']['values'][0]['NDA_FORM']['options']));
        if (this.fields['NDA_PURPOSE_SECTION']['values'][0]['NDA_PURPOSE']['value']  === 'INVESTMENT_PROJECT') {
          this.fields['NDA_FORM_SECTION']['values'][0]['NDA_FORM']['value'] = 'STANDARD';
          this.fields['NDA_FORM_SECTION']['values'][0]['NDA_FORM']['options'] = reject(this.values['NDA_FORM_SECTION']['values'][0]['NDA_FORM']['options'], { value: 'PARTNER'});
        }
      }
    }
  }
}
</script>

<style module lang="scss">
.root {
  :global(.v-card) {
    border: 1px solid #dee2e6;
  }
  .row + .row {
    border-top: 1px dashed #dee2e6;
  }
}
</style>
