<template>
  <div :class="$style.root">
    <template v-if="!readonly">
      <VSelect v-if="type === 'BOOL'" v-bind="{...setting}" @input="onInput" />
      <VSelect v-else-if="type === 'LIST'" v-bind="{...setting}" @input="onInput" />
      <VRadioGroup v-else-if="type === 'RADIO'" class="mt-0" v-bind="{...setting}" @change="onInput">
        <VRadio v-for="{value, text} in options" :label="text" :value="value" :key="value" />
      </VRadioGroup>
      <FileInput v-else-if="type === 'FILE'" v-bind="{...setting}" @input="onInput" />
      <DateInput v-else-if="type === 'DATE'" v-bind="{...setting}" @input="onInput" />
      <VTextarea v-else-if="type === 'TEXTAREA'" v-bind="{...setting}" @input="onInput" />
      <VTextField v-else v-bind="{...setting}" @input="onInput" />
    </template>
    <template v-else>
      <FileFieldStatic v-if="type === 'FILE'" v-bind="{...setting}" />
      <StaticField v-else v-bind="{...setting}" />
    </template>
  </div>
</template>

<script>
import FileInput from '@/components/nda/FileInput/FileInput';
import DateInput from '@/components/general/DateInput/DateInput';
import StaticField from '@/components/general/StaticField/StaticField';
import FileFieldStatic from '@/components/nda/FileFieldStatic/FileFieldStatic';
export default {
  name: 'TypedField',
  components: {
    FileInput,
    DateInput,
    StaticField,
    FileFieldStatic,
  },
  props: {
    type: { type: String, default: 'TEXT' },
    label: { type: String },
    value: { type: [String, Number, Boolean] },
    code: { type: String },
    min: { type: String },
    max: { type: String },
    readonly: { type: Boolean },
    placeholder: { type: String },
    options: { type: Array, default: () => [] },
    rules: { type: [Array, Function], default: () => [] },
  },
  computed: {
    setting() {
      return {
        label: this.type !== 'RADIO' ? this.label : null,
        value: this.value,
        name: this.code,
        rules: this.rules,
        placeholder: this.placeholder,
        outlined: true,
        dense: true,
        hideDetails: 'auto',
        min: this.min,
        max: this.max,
        items: this.type === 'BOOL' ? [{ text: 'Да', value: '1' }, { text: 'Нет', value: '0' }] : this.options,
      };
    },
  },
  methods: {
    onInput(value) {
      this.$emit('input', value);
    }
  },
}
</script>
<style module lang="scss">
.root {}
</style>
