<template>
  <FileInput :label="label" :value="value">
    <template v-slot:default="{ value, file, size, current }">
      <StaticField :label="label" :value="file || value" />
      <div v-if="current" class="mt-2">
        <a :href="current.url" class="text-decoration-none">
          <VImg v-if="current.src" :lazySrc="current.src" height="60" width="60" aspect-ratio="1" />
          <div :class="$style.file" v-else>
            <VIcon color="primary" large >mdi-file</VIcon>
            {{current.name}}
          </div>
        </a>
      </div>
    </template>
  </FileInput>
</template>

<script>
import FileInput from '@/components/nda/FileInput/FileInput';
import StaticField from '@/components/general/StaticField/StaticField';
export default {
  name: 'FileFieldStatic',
  components: {
    FileInput,
    StaticField,
  },
  props: {
    label: { type: String },
    value: { type: [String, Number] },
  }
}
</script>
